import React from "react"
import {
  Message,
  Transition,
} from "semantic-ui-react"
import Color from "../style/Color"

const STYLE = { padding: "1em" };

const WizardMessage = ({ visible, children, size=null, error=false, duration=1000, timeoutDuration=3000, onTimeout=null }) => (
  <Transition duration={duration} visible={visible} onShow={onTimeout == null ? null : () => setTimeout(onTimeout, timeoutDuration)} compact>
    <div style={STYLE}><Message info={!error} error={error} size={size} compact color={!error ? Color.accent : null}>{children}</Message></div>
  </Transition>
);

export default WizardMessage;
