import React from "react"
import { navigate } from "gatsby"
import Countdown from "react-countdown"
import Layout from "../ui/Layout"
import connectStore from "../state/connectStore"
import WizardMessage from "../ui/WizardMessage"
import {
  Container,
  
} from "semantic-ui-react"

const DURATION = 1000 * 10;

const countdownStyle = { fontSize: "3em" };

const CountDownRenderer = ({
  minutes,
  seconds,
  completed,
}) => {
  const m = minutes < 10 ? "0" + minutes : minutes;
  const s = seconds < 10 ? "0" + seconds : seconds;

  if(completed) {
    setTimeout(() => navigate("/order-qr-reader"), 3000);
    return (
      <WizardMessage size="huge">Your order is ready!</WizardMessage>
    );
  }

  return (
    <Container text style={countdownStyle}>
      {m + ":" + s}
    </Container>
  );
};

const Page = ({ location, order }) => {
  return (
    <Layout location={location} validate={false} header="Thank you! Your order is coming up.">
      <Countdown date={Date.now() + DURATION} renderer={CountDownRenderer} />
    </Layout>
  );
};

export default connectStore(Page);
